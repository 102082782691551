* {
    padding: 0;
    margin: 0;
}

.inputs {
    width: 100%;
}

.input {
    width: 100%;
    margin: 20px auto;
}

.root {
    padding: 20px;
    margin-top: 100px;
    position: absolute;
    width: 600px;
    min-height: 100%;
    top: 0;
    left: calc(50% - 300px);
    text-align: center;
}

.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    position: fixed;
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;
}

.modal_active {
    opacity: 1;
    pointer-events: all;
}

.alert {
    transform: scale(0);
    width: 350px;
    min-height: 130px;
    transition: 0.4s all;
    position: relative;
}

.alert_active {
    transform: scale(1);
}

.root_active {
    width: 100vw;
    height: 100vh;
}

.button {
    padding: 12px 30px;
}

.controllerInput {
    padding-left: 10px;
    width: 100%;
    margin: 10px auto;
}

@media only screen and (max-width: 650px) {
    .root {
        width: 350px;
        left: calc(50% - 175px);
    }

    .captchaImg {
        height: 80px;
    }

    .alert {
        left: calc(50% - 165px);
        width: 300px;
    }
}
.file {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin: 5px;
}
